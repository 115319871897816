import { get, post, put, del } from '@/services/http';

export class DiscountModel {
  public id: number;
  public createdAt?: string;
  public updatedAt?: string;
  public code: string;
  public rate?: number;
  public limitIntervals: number;
  public activatedAt?: string;
  public status: string;
  public cancelledAt?: string;
  public merchantId: number;
  public kind: string;
  public limitUsageNumber: number;
  public appliedTime?: number;
  public merchantNames?: string[] | undefined;
  public type: string;
  public fixedDiscountAmount?: number;
  public fixedPrice?: number;

  constructor(data: any = {}) {
    this.id = data.id;
    this.code = data.code;
    this.rate = data.rate;
    this.limitIntervals = data.limitIntervals || 1;
    this.status = data.status || 'ACTIVE';
    this.merchantId = data.merchantId;
    this.kind = data.kind || 'REUSE';
    this.limitUsageNumber = data.limitUsageNumber || -1;
    this.type = data.type || 'PERCENTAGE_DISCOUNT';
    this.fixedDiscountAmount = data.fixedDiscountAmount;
    this.fixedPrice = data.fixedPrice;
  }

  public async fetch() {
    const res: any = await get(`/discounts/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    this.cleanData();
    const res: any = await post('/discounts', {
      rate: this.rate,
      limitIntervals: this.limitIntervals,
      status: 'ACTIVE',
      merchantId: this.merchantId,
      kind: this.kind,
      limitUsageNumber: this.limitUsageNumber,
      merchantNames: this.merchantNames,
      type: this.type,
      discountAmount: this.fixedDiscountAmount,
      fixedPrice: this.fixedPrice,
    });
    this.mapData(res.data);
  }

  public async update() {
    this.cleanData();
    const res: any = await put(`/discounts/${this.id}`, {
      rate: this.rate,
      limitIntervals: this.limitIntervals,
      status: this.status,
      merchantId: this.merchantId,
      kind: this.kind,
      limitUsageNumber: this.limitUsageNumber,
      merchantNames: this.merchantNames,
      type: this.type,
      discountAmount: this.fixedDiscountAmount,
      fixedPrice: this.fixedPrice,
    });
  }

  public async del() {
    const res: any = await del(`/discounts/${this.id}`);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.code = data.code;
    this.rate = data.rate;
    this.limitIntervals = data.limitIntervals;
    this.activatedAt = data.activatedAt;
    this.status = data.status;
    this.cancelledAt = data.cancelledAt;
    this.merchantId = data.merchantId;
    this.kind = data.kind;
    this.limitUsageNumber = data.limitUsageNumber;
    this.appliedTime = data.appliedTime;
    this.type = data.type || 'PERCENTAGE_DISCOUNT';
    this.fixedDiscountAmount = data.discountAmount;
    this.fixedPrice = data.fixedPrice;
    if (data.merchantNames && data.merchantNames.length > 0) {
      this.merchantNames = data.merchantNames;
    }
  }

  private cleanData() {
    switch (this.type) {
      case 'PERCENTAGE_DISCOUNT':
        this.fixedDiscountAmount = undefined;
        this.fixedPrice = undefined;
        break;
      case 'FIXED_DISCOUNT_AMOUNT':
        this.rate = undefined;
        this.fixedPrice = undefined;
        break;
      case 'FIXED_PRICE':
        this.rate = undefined;
        this.fixedDiscountAmount = undefined;
        break;
    }
  }
}
