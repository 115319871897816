
import { Component, Vue } from 'vue-property-decorator';
import { DiscountList } from '@/collections/discounts';

@Component({
  components: {
  },
})
export default class Discounts extends Vue {
  public isLoading: boolean = true;
  public discounts: DiscountList = new DiscountList();
  public currentPage: number = 1;
  public perPage: number = 50;
  public lastPage: number = 1;
  public copyLoading: boolean = false;
  public copyMessage: string = '';

  public async created() {
    this.isLoading = true;
    await this.discounts.fetch(this.currentPage, this.perPage);
    this.lastPage = this.discounts.lastPage;
    this.isLoading = false;
  }

  public async wait(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  public async copyToClipboard(discountCode: string) {
    const url: string = 'https://my.assisty.ai/auto_subscription?discount=' + discountCode;
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) {
      //
    }
    this.copyLoading = true;
    this.copyMessage = url;
    await this.wait(4000);
    this.copyLoading = false;
  }

  public async fetchData() {
    this.discounts = new DiscountList();
    await this.discounts.fetch(this.currentPage, this.perPage);
    this.lastPage = this.discounts.lastPage;
  }

  public getDiscountTypeLabel(type: string): string {
    const discountTypeMapping: { [key: string]: string } = {
      'FIXED_DISCOUNT_AMOUNT': 'Fixed Discount Amount',
      'PERCENTAGE_DISCOUNT': 'Percentage Discount',
      'FIXED_PRICE': 'Fixed Price'
    };
    return discountTypeMapping[type] || type;
  }

  public getDiscountTypeColor(type: string): string {
    const discountTypeColorMapping: { [key: string]: string } = {
      'FIXED_DISCOUNT_AMOUNT': 'blue',
      'PERCENTAGE_DISCOUNT': 'purple',
      'FIXED_PRICE': 'orange'
    };
    return discountTypeColorMapping[type] || 'gray';
  }

  public getRateDisplay(item: any): string {
    switch (item.type) {
      case 'FIXED_DISCOUNT_AMOUNT':
        return `$${item.fixedDiscountAmount}`;
      case 'PERCENTAGE_DISCOUNT':
        return `${item.rate}%`;
      case 'FIXED_PRICE':
        return `$${item.fixedPrice}`;
      default:
        return '';
    }
  }
}
